import React, { useRef } from 'react';
import { motion, useMotionTemplate, useTransform, useViewportScroll } from 'framer-motion';
import useSize from '@react-hook/size';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import useDeviceScreen from '../hooks/useDeviceScreen';

import PC from '../assets/img/test.jpeg';
import Tablet from '../assets/img/host.jpeg';
import Phone from '../assets/img/phone.jpeg';
import Table from '../assets/img/build.jpeg';
import Form from '../assets/img/form.jpeg';
import Card from '../assets/img/card.jpeg';
import Play from '../components/icon/Play';

const useStyles = createUseStyles({
	home: {
		minHeight: '90vh',
	},
	landingText: {
		zIndex: 1,
	},
});

const SectionLanding = () => {
	const classes = useStyles();

	const { scrollYProgress } = useViewportScroll();
	// eslint-disable-next-line no-unused-vars
	const deviceScreen = useDeviceScreen();
	const ref = useRef(null);
	const [width] = useSize(ref);

	const time = 0.05;

	// eslint-disable-next-line no-unused-vars
	const BASE_SIZE = {
		PC: { width: 3840, height: 2242 },
		TABLET: 2734,
		PHONE: 1125,
		TABLE: { width: 2222, height: 1118 },
		FORM: { width: 2426, height: 536 },
		CARD: { width: 660, height: 992 },
	};

	const scale = (size, ratio = 1.4) => (size / (BASE_SIZE.PC.width / width)) * 0.9 * ratio;

	const calcDeg = useTransform(scrollYProgress, [0, time], [45, 0]);
	const calcOpacity = useTransform(scrollYProgress, [0, time], [0.4, 0]);

	// PC
	const transformPC = useMotionTemplate`rotate3d(1, 1, -1, ${calcDeg}deg) translate3d(0%, 0%, 10px)`;

	// Tablet
	const translateXAxisTablet = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.width) * 0.65, scale(BASE_SIZE.PC.width) * 0.3],
	);
	const translateYAxisTablet = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.height) * -0.2, scale(BASE_SIZE.PC.height) * 0.175],
	);
	const transformTablet = useMotionTemplate`rotate3d(1, 1, -1, ${calcDeg}deg) translate3d(${translateXAxisTablet}px, ${translateYAxisTablet}px, 100px)`;

	// Phone
	const translateXAxisPhone = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.width) * 0.6, scale(BASE_SIZE.PC.width) * 0.7],
	);
	const translateYAxisPhone = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.height) * 1, scale(BASE_SIZE.PC.height) * 0.5],
	);
	const transformPhone = useMotionTemplate`rotate3d(1, 1, -1, ${calcDeg}deg) translate3d(${translateXAxisPhone}px, ${translateYAxisPhone}px, 200px)`;

	// Table
	const translateXAxisTable = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.width) * -0.2, 40],
	);
	const translateYAxisTable = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.height) * -1, 40],
	);
	const transformTable = useMotionTemplate`rotate3d(1, 1, -1, ${calcDeg}deg) translate3d(${translateXAxisTable}px, ${translateYAxisTable}px, 21px)`;

	// Form
	const translateXAxisForm = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.width) * -0.9, 20],
	);
	const translateYAxisForm = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.PC.height) * 0.1, scale(BASE_SIZE.PC.height) * 0.4],
	);
	const transformForm = useMotionTemplate`rotate3d(1, 1, -1, ${calcDeg}deg) translate3d(${translateXAxisForm}px, ${translateYAxisForm}px, 22px)`;

	// Card
	const translateXAxisCard = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.CARD.width) * 2.5, scale(BASE_SIZE.CARD.width) * 3.5],
	);
	const translateYAxisCard = useTransform(
		scrollYProgress,
		[0, time],
		[scale(BASE_SIZE.CARD.height) * -1, 20],
	);
	const transformCard = useMotionTemplate`rotate3d(1, 1, -1, ${calcDeg}deg) translate3d(${translateXAxisCard}px, ${translateYAxisCard}px, 22px)`;

	return (
		<div id='home' className={classNames('row', classes.home)}>
			<div
				className={classNames(
					'col-xl-4 d-flex align-items-lg-center c-h-lg-down-100 px-3',
					classes.landingText,
				)}>
				<div className='py-5 py-lg-0'>
					<h1 className='fw-bold h5 text-light mb-5'>AI DEVELOPMENT PLATFORM</h1>
					<h2 className='fw-bold display-2 mb-4'>AI Has Never Been This Easy</h2>
					<p className='h4 mb-5 text-muted fw-light'>
						ai4us Is Your One-Stop No-Code AI Development Platform.
					</p>
					<div className='d-flex align-content-stretch'>
						<a
							href='https://app.ai4us.net/newModel'
							target='_blank'
							className='btn btn-info btn-lg px-5 py-4 rounded-3 fw-bold me-4'
							rel='noreferrer'>
							Build My First AI
						</a>
						<a
							href='#examples'
							className='btn text-decoration-none btn-lg px-5 rounded-3 btn-outline-dark fw-bold d-flex align-items-center'>
							<Play className='fs-1' />
							<span>Preview</span>
						</a>
					</div>
				</div>
			</div>
			<div
				ref={ref}
				className='col-xl-8 d-flex align-items-center c-h-lg-down-100 landing-device'>
				{/* BEGIN :: With opacity */}
				<>
					<motion.img
						src={Card}
						alt='Card'
						className='position-absolute'
						width={scale(BASE_SIZE.CARD.width)}
						style={{
							willChange: 'transform',
							transform: transformCard,
							opacity: calcOpacity,
						}}
					/>
					<motion.img
						src={Table}
						alt='Table'
						className='shadow-lg rounded-1 position-absolute'
						width={scale(BASE_SIZE.TABLE.width)}
						style={{
							willChange: 'transform',
							transform: transformTable,
							opacity: calcOpacity,
						}}
					/>
					<motion.img
						src={Form}
						alt='Form'
						className='shadow-lg rounded-1 position-absolute'
						width={scale(BASE_SIZE.FORM.width)}
						style={{
							willChange: 'transform',
							transform: transformForm,
							opacity: calcOpacity,
						}}
					/>
				</>
				{/* END :: With opacity */}
				{/* BEGIN :: Images */}
				<>
					<motion.img
						src={PC}
						alt='Pc'
						className='shadow-lg rounded-1 position-absolute'
						width={scale(BASE_SIZE.PC.width)}
						height={scale(BASE_SIZE.PC.height)}
						style={{
							willChange: 'transform',
							transform: transformPC,
						}}
					/>
					<motion.img
						src={Tablet}
						alt='Tablet'
						className='shadow-lg rounded-1 position-absolute'
						width={scale(BASE_SIZE.TABLET)}
						style={{
							willChange: 'transform',
							transform: transformTablet,
						}}
					/>
					<motion.img
						src={Phone}
						alt='Phone'
						className='shadow-lg position-absolute'
						width={scale(BASE_SIZE.PHONE, 0.6)}
						style={{
							willChange: 'transform',
							transform: transformPhone,
							borderRadius: scale(BASE_SIZE.PHONE, 0.6) / 17,
						}}
					/>
				</>
				{/* BEGIN :: Images */}
			</div>
		</div>
	);
};

export default SectionLanding;
