import React from 'react';
import { Person1 } from '../components/Images';

const SectionBuyNow = () => (
	<div
		id='integrated'
		className='row scroll-margin g-4'
		style={{ paddingTop: '4rem', paddingBottom: '5rem' }}>
		<div
			className='col-11 mx-auto rounded-2'
			style={{
				paddingTop: '10rem',
				paddingBottom: '10rem',
				backgroundColor: 'var(--bs-gray-200)',
			}}>
			<div className='d-flex'>
				<img
					src={Person1}
					alt=''
					className='position-absolute'
					width='20%'
					style={{
						transform: ' translate(1%, -10%)',
					}}
				/>
				<div className='text-center position-relative w-100'>
					<div className='display-1 fw-bold'>AI has never been this easy!</div>
					<div className='lead'>Come and see for yourself!</div>
					<a
						href="https://app.ai4us.net/newModel"
						className='btn btn-info btn-lg px-5 py-3 rounded-1 fw-bold mt-5'>
						Build My First Model
					</a>
				</div>
			</div>
		</div>
	</div>
);

export default SectionBuyNow;
