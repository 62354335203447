import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Logo from '../components/Logo';

const useStyles = createUseStyles({
	nav: {
		transform: 'translateZ(9999px)',
	},
});

const Navigation = ({ setHeaderHeight }) => {
	// eslint-disable-next-line no-unused-vars
	const [ref, { height }] = useMeasure();
	useEffect(() => {
		setHeaderHeight(height);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [height]);

	const classes = useStyles();

	return (
		<nav
			ref={ref}
			className={classNames(
				'header navbar navbar-expand-lg navbar-light sticky-top',
				classes.nav,
			)}>
			<div className='container-xxl'>
				<a className='navbar-brand' aria-label='Facit Logo' href='/'>
					<Logo height={64} />
				</a>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarScroll'
					aria-controls='navbarScroll'
					aria-expanded='false'
					aria-label='Toggle navigation'>
					<span className='navbar-toggler-icon' />
				</button>
				<div className='collapse navbar-collapse' id='navbarScroll'>
					<ul
						id='main-navbar'
						className='navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll align-items-center'
						style={{ '--bs-scroll-height': '100vh' }}>
						<li className='nav-item'>
							<a className='nav-link' aria-current='page' href='#home'>
								Home
							</a>
						</li>
						<li className='nav-item'>
							<a className='nav-link' href='#demos'>
								Features
							</a>
						</li>
						<li className='nav-item'>
							<a className='nav-link' href='#examples'>
								Examples
							</a>
						</li>
						{/* <li className='nav-item dropdown'> */}
						{/*	<a */}
						{/*		className='nav-link dropdown-toggle' */}
						{/*		href='/' */}
						{/*		id='navbarScrollingDropdown' */}
						{/*		role='button' */}
						{/*		data-bs-toggle='dropdown' */}
						{/*		aria-expanded='false'> */}
						{/*		Apps */}
						{/*	</a> */}
						{/*	<ul className='dropdown-menu' aria-labelledby='navbarScrollingDropdown'> */}
						{/*		<li> */}
						{/*			<a className='dropdown-item' href='#apps'> */}
						{/*				Sales */}
						{/*			</a> */}
						{/*		</li> */}
						{/*		<li> */}
						{/*			<a className='dropdown-item' href='#apps-appointment'> */}
						{/*				Appointment */}
						{/*			</a> */}
						{/*		</li> */}
						{/*		<li> */}
						{/*			<hr className='dropdown-divider' /> */}
						{/*		</li> */}
						{/*		<li> */}
						{/*			<a className='dropdown-item' href='#apps-chat'> */}
						{/*				Chat */}
						{/*			</a> */}
						{/*		</li> */}
						{/*	</ul> */}
						{/* </li> */}
						<li className='nav-item'>
							<a
								className='btn btn-info px-5 py-4 rounded-3'
								href='https://app.ai4us.net/newModel'
								target='_blank'
								rel='noreferrer'>
								Let's Build
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

Navigation.propTypes = {
	setHeaderHeight: PropTypes.func.isRequired,
};

export default Navigation;
