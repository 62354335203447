import * as React from 'react';

function SvgPlay(props) {
	return (
		<svg
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			className='svg-icon'
			{...props}>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M9.829 18.277l6.74-5.88a.5.5 0 00.002-.752L9.83 5.728a.5.5 0 00-.83.376V17.9a.5.5 0 00.829.377z'
					fill='currentColor'
				/>
			</g>
		</svg>
	);
}

export default SvgPlay;
