import React from 'react';
import PropTypes from 'prop-types';
import logo from './logos/logo.png';

const Logo = ({ width, height }) => {
	return (
		<div >
			<div style={{ paddingLeft: "25px" }}>
				<img style={{ height: "50px" }} src={logo}></img>
			</div>
		</div>
	);
};

Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
