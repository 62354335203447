import React, { useCallback, useEffect, useRef } from 'react';
import { useSpring, animated as a, interpolate } from 'react-spring';
import useWindowScroll from '@react-hook/window-scroll';
import useScrollWidth from '../hooks/useScrollWidth';
import YouTube from 'react-youtube';
import {
	Component1,
	Component10,
	Component11,
	Component12,
	Component13,
	Component14,
	Component15,
	Component16,
	Component17,
	Component2,
	Component3,
	Component4,
	Component5,
	Component6,
	Component7,
	Component8,
	Component9,
} from '../components/Images';
import YoutubeEmbed from '../components/content/YoutubeEmbed/YoutubeEmbed';
// import { Youtube } from '../components/icon/bootstrap';

// eslint-disable-next-line no-unused-vars,react/prop-types
function ScrollCarousel({ children, className }) {
	const refHeight = useRef(null);
	const refTransform = useRef(null);

	const { scrollWidth } = useScrollWidth(refTransform);

	// the argument is the fps that the hook uses,
	// since react spring interpolates values we can safely reduce this below 60
	const scrollY = useWindowScroll(45);
	const [{ st, xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }));

	useEffect(() => {
		set({ st: scrollY });
	}, [scrollY, set]);

	const onMouseMove = useCallback(
		({ clientX: x, clientY: y }) =>
			set({ xy: [x - window.innerWidth / 2, y - window.innerHeight / 2] }),
		// eslint-disable-next-line  react-hooks/exhaustive-deps
		[],
	);

	const top = refHeight.current ? refHeight.current.offsetTop : 0;
	const width = refHeight.current ? refHeight.current.offsetWidth : 0;

	// we want to set the scrolling element *height* to the value of the *width* of the horizontal content
	// plus some other calculations to convert it from a width to a height value
	const elHeight = scrollWidth - (window.innerWidth - window.innerHeight) + width * 0.5; // scroll away when final viewport width is 0.5 done

	// eslint-disable-next-line no-shadow
	const interpTransform = interpolate([st, xy], (o, xy) => {
		const mouseMoveDepth = 40; // not necessary, but nice to have
		const x = width - (top - o) - width;

		// (width * 0.5) so that it starts moving just slightly before it comes into view
		if (x < -window.innerHeight - width * 0.5) {
			// element is not yet in view, we're currently above it. so don't animate the translate value
			return `translate3d(${window.innerHeight}px, 0, 0)`;
		}

		if (Math.abs(x) > elHeight) {
			// element is not in view, currently below it.
			return `translate3d(${elHeight}px, 0, 0)`;
		}

		// else animate as usual
		return `translate3d(${-x + -xy[0] / mouseMoveDepth}px, ${-xy[1] / mouseMoveDepth}px, 0)`;
	});

	return (
		<div
			onMouseMove={onMouseMove}
			ref={refHeight}
			// style={{ height: elHeight }}
			className={className}>
			<div id='examples' className='position-sticky top-0 overflow-hidden'>
				<div className='container-fluid' style={{ marginTop: '13vh' }}>
					<div className='row g-4'>
						<div className='col-12 d-flex justify-content-center'>
							<div className='display-2 fw-bold scroll-margin'>Tutorial</div>
						</div>
						<div className='col-12 d-flex justify-content-center'>
							<div className='text-muted lead'>
								Integrate AI into your marketing campaign, business analytics pipelines, or your VR app. The possibilities are endless.
							</div>
						</div>
					</div>
				</div>
				<div className='tube-container mt-5'>
					<YoutubeEmbed embedId="bTEmwI_T9iE" />
				</div>
			</div>
		</div>
	);
}

const SectionExamples = () => (
	<>
		<ScrollCarousel>
			<div className='box d-flex align-items-center'>
				<div className='row align-items-center'>
					<div className='col-4'>
						<img src={Component1} alt='' className='img-fluid' />
					</div>
					<div className='col-8'>
						<img src={Component2} alt='' className='img-fluid' />
					</div>
					<div className='col-8'>
						<img src={Component3} alt='' className='img-fluid' />
					</div>
					<div className='col-4'>
						<img src={Component4} alt='' className='img-fluid' />
					</div>
					<div className='col-12'>
						<img src={Component5} alt='' className='img-fluid' />
					</div>
				</div>
			</div>
			<div className='box d-flex align-items-center'>
				<div className='row align-items-center'>
					<div className='col-12'>
						<img src={Component6} alt='' className='img-fluid' />
					</div>
					<div className='col-12'>
						<img src={Component7} alt='' className='img-fluid' />
					</div>
				</div>
			</div>
			<div className='box d-flex align-items-center'>
				<div className='row align-items-center'>
					<div className='col-8'>
						<div className='row'>
							<div className='col-6'>
								<img src={Component13} alt='' className='img-fluid' />
							</div>
							<div className='col-6'>
								<img src={Component12} alt='' className='img-fluid' />
							</div>
							<div className='col-12'>
								<img src={Component9} alt='' className='img-fluid' />
							</div>
						</div>
					</div>
					<div className='col-4'>
						<div className='row'>
							<div className='col-12'>
								<img src={Component8} alt='' className='img-fluid' />
							</div>
							<div className='col-12'>
								<img src={Component11} alt='' className='img-fluid' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='box d-flex align-items-center'>
				<div className='row align-items-center'>
					<div className='col-6'>
						<img src={Component10} alt='' className='img-fluid' />
					</div>
					<div className='col-6'>
						<img src={Component15} alt='' className='img-fluid' />
					</div>
					<div className='col-12'>
						<img src={Component14} alt='' className='img-fluid' />
					</div>
				</div>
			</div>
			{/* <div className='box d-flex align-items-center'>
				<div className='row align-items-center'>
					<div className='col-10'>
						<img src={Component16} alt='' className='img-fluid' />
					</div>
					<div className='col-10'>
						<img src={Component17} alt='' className='img-fluid' />
					</div>
				</div>
			</div> */}
		</ScrollCarousel>
	</>
);

export default SectionExamples;
