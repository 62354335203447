import React from 'react';
import { useNavigate } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { DemoDefault, Demo2, Devices, Demo3, Demo4, Demo5 } from '../components/Images';

const useStyles = createUseStyles({
	demos: {
		paddingTop: '10rem',
		paddingBottom: '10rem',
	},
	tile: {
		height: 600,
	},
	chooseYourSide: {
		background: 'linear-gradient(to top right, #1f2d3d, #75a3cf);',
	},
});

const SectionDemos = () => {

	const classes = useStyles({});
	const demosData = [
		{
			id: 1,
			title: 'Build',
			desc: 'Build an AI model using our data catalog or use your own data.',
			img: DemoDefault,
			link: 'https://app.ai4us.net/newModel',
		},
		{
			id: 2,
			title: 'Host',
			desc: "Download or host your model at a live endpoint and call the model from your app.",
			img: Demo2,
			link: 'https://app.ai4us.net/newModel',
		},
		{
			id: 3,
			title: 'Debug',
			desc: "View your model's performance in-depth and tune it accordingly.",
			img: Demo3,
			link: 'https://app.ai4us.net/newModel',
		},
		{
			id: 4,
			title: 'Collaborate',
			desc: 'Add teammates to your workspace to help you build the future.',
			img: Demo4,
			link: 'https://app.ai4us.net/newModel',
		},
		{
			id: 5,
			title: 'Scale',
			desc: 'Use our libraries and APIs to call your model from any app on any device.',
			img: Demo5,
			link: 'https://app.ai4us.net/newModel',
		},
	];
	return (
		<div id='demos' className={classNames('row scroll-margin', classes.demos)}>
			<div className='col-12 display-4 fw-bold mb-3'>Progress Is NOT A Slow Process</div>
			<div className='col-md-8 h4 mb-5'>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				Leverage public data through our <b>integrated data catalog</b> or bring your own data. Rather than spend days setting up infrastructure, leverage our pre-built AI recipes to transform your data into AI models and easily integrate them into your killer app!
			</div>

			<div className='col-12 mt-5'>
				<div className='row g-4 align-items-center'>
					<div className='col-lg-4'>
						<a
							href="https://app.ai4us.net/newModel">
							<div
								href="https://app.ai4us.net/newModel"
								className={classNames(
									'card border-0 text-white',
									classes.tile,
									classes.chooseYourSide,
								)}>
								<div
									className={classNames('card-body px-4 py-5 choose-your-side-bg')}
									style={{ backgroundImage: `url(${Devices}` }}>
									<p className='h4 mt-3'>Talk is Cheap</p>
									<p className='lead'>
										Click here to build and integrate your first AI model in <b>minutes</b>.
									</p>
								</div>
							</div>
						</a>
					</div>
					{demosData.map((i) => (
						<div key={i.id} className='col-lg-4 preview-container'>
							<div
								className={classNames(
									'card overflow-hidden border-0',
									classes.tile,
								)}>
								<div className='card-header bg-transparent px-4 py-5'>
									<div className='row mb-3 align-items-center'>
										<div className='col'>
											<p className='h4'>{i.title}</p>
										</div>
										<div className='col-auto'>
											<a
												href="https://app.ai4us.net/newModel"
												className='btn btn-outline-dark btn-lg px-5 py-3 rounded-1 fw-bold'>
												Let's Build
											</a>
										</div>
									</div>
									<div className='row'>
										<div className='col'>
											<p className='lead text-muted'>{i.desc}</p>
										</div>
									</div>
								</div>
								<div className='card-body d-flex align-items-center'>
									<img
										src={i.img}
										alt={`${i.title} Version`}
										className='preview-image'
									/>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default SectionDemos;
