import React, { useState } from 'react';
import Navigation from './layout/Navigation';
import Main from './layout/Main';
// import Footer from './layout/Footer';

const App = () => {
	const [headerHeight, setHeaderHeight] = useState(60);

	return (

		<div className='container-fluid p-0 TEST'>
			<Navigation setHeaderHeight={setHeaderHeight} />
			<Main headerHeight={headerHeight} />
			{/* <Footer /> */}
		</div>

	);
};

export default App;
