import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import {
	Chat,
	Header,
	Menu,
	QuickMenu,
	Search,
	SubBackUser,
	SubBreadcrumb,
	SubButton3,
	Subheader,
	SubTab2,
	SubTeam,
	User,
} from '../components/HeadersImages';

const useStyles = createUseStyles({
	facitHeader: {
		height: 500,
		marginTop: -200,
		'& .header-img': {
			boxShadow: '0 1.6rem 3rem rgb(0 0 0 / 10%)',
			borderRadius: 13,
			zIndex: 1,
		},
	},
	facitSubeader: {
		height: 500,
		marginTop: -400,
		'& .header-img': {
			boxShadow: '0 1.6rem 3rem rgb(0 0 0 / 10%)',
			borderRadius: 13,
			zIndex: 1,
		},
	},
	items: {
		inset: 0,
	},
	animationContainer: {
		overflowX: 'hidden',
	},
});

const SectionHeaders = () => {
	const classes = useStyles({});
	return (
		<div className={classNames('container-fluid', classes.animationContainer)}>
			<div className='container'>
				<div className='row'>
					<div
						className='col-12 display-4 fw-bold mb-3'
						style={{ marginTop: 50, zIndex: 2 }}>
						<span className='active-text-header'>Header</span> &{' '}
						<span className='active-text-subheader'>Subheader</span>
					</div>
					<div className='col-md-8 h4 mb-5' style={{ zIndex: 2 }}>
						You are free in <strong>Facit</strong>. You can put anything you want on
						that page in
						{/* eslint-disable-next-line react/no-unescaped-entities */}
						"Header" & "Subheader" ("of course if you want 😎") on each page. There is
						no such thing as impossible with us, no trying too hard.
					</div>
					<div className='col-12'>
						<div
							className={classNames(
								'facit-header d-flex align-items-center position-relative',
								classes.facitHeader,
							)}>
							<img src={Header} className='img-fluid header-img' alt='' />
							<div
								className={classNames(
									'd-flex align-items-center position-absolute',
									classes.items,
								)}>
								<img src={Chat} alt='Chat' className='hi-chat' />
								<img src={Search} alt='Search' className='hi-search' />
								<img src={Menu} alt='Menu' className='hi-menu' />
								<img src={QuickMenu} alt='QuickMenu' className='hi-quickmenu' />
								<img src={User} alt='User' className='hi-user' />
							</div>
						</div>
					</div>
					<div className='col-12'>
						<div
							className={classNames(
								'facit-header d-flex align-items-center position-relative',
								classes.facitSubeader,
							)}>
							<img src={Subheader} className='img-fluid header-img' alt='' />
							<div
								className={classNames(
									'd-flex align-items-center position-absolute',
									classes.items,
								)}>
								<img src={SubTeam} alt='SubTeam' className='hi-subteam' />
								<img src={SubButton3} alt='QuickMenu' className='hi-buttons' />
								<img src={SubTab2} alt='User' className='hi-tab' />
								<img src={SubBackUser} alt='Search' className='hi-backuser' />
								<img src={SubBreadcrumb} alt='Menu' className='hi-breadcrumb' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionHeaders;
