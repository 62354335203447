import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import SectionLanding from '../pages/SectionLanding';
import SectionExamples from '../pages/SectionExamples';
import SectionDemos from '../pages/SectionDemos';
import SectionLayouts from '../pages/SectionLayouts';
import SectionIntegrated from '../pages/SectionIntegrated';
import SectionBuyNow from '../pages/SectionBuyNow';
import SectionCredits from '../pages/SectionCredits';
import DATA from '../constant';
import SectionHeaders from '../pages/SectionHeaders';
import { DemoLite } from '../components/Images';

const useStyles = createUseStyles({
	main: {
		top: (headerHeight) => headerHeight * -1.5,
	},
	bgGrayAfter: {
		backgroundColor: DATA.GRAY,
		background: `linear-gradient(180deg, ${DATA.GRAY} 0%, ${DATA.WHITE} 100%)`,
	},
	animationContainer: {
		overflowX: 'hidden',
	},
});

const Main = ({ headerHeight }) => {
	const classes = useStyles(headerHeight);
	return (
		<main
			data-bs-spy='scroll'
			data-bs-target='#main-navbar'
			data-bs-offset='0'
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex='0'
			className={classes.main}>
			<div className={classNames('container-fluid p-0', classes.animationContainer)}>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<SectionLanding />
							{/* <SectionCredits /> */}
						</div>
					</div>
				</div>
			</div>
			<div className={classNames('container-fluid', classes.bgGrayAfter)}>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<SectionDemos />
						</div>
					</div>
					<div className='row'>
						<div className='col-12 preview-container'>
							<div className='card overflow-hidden'>
								<div className='row'>
									<div className='col-lg-4'>
										<div className='card-header bg-transparent px-4 py-5'>
											<p className='h4'>You're Not Alone</p>
											<p className='lead'>
												<span className='fw-bold'>
													We're here to help you build!
												</span>{' '}
												<span className='text-muted'>
													Speak with our customer support through email and live chat or schedule a consultation session.
												</span>
											</p>
											<a
												href='mailto:support@ai4us.net'
												className='btn btn-outline-dark btn-lg px-5 py-3 rounded-1 fw-bold'>
												Contact us
											</a>
										</div>
									</div>
									<div className='col-lg-8'>
										<div className='card-body position-relative'>
											<img
												src={DemoLite}
												alt='Lite Version'
												className='preview-image-3'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <SectionHeaders /> */}

			<SectionExamples />
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-12'>
						{/* <SectionLayouts /> */}
						{/* <SectionIntegrated /> */}
						<SectionBuyNow />
					</div>
				</div>
			</div>
		</main>
	);
};
Main.propTypes = {
	headerHeight: PropTypes.number.isRequired,
};

export default Main;
